import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  ListSubheader,
  Badge,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CodeIcon from '@mui/icons-material/Code';
import ListIcon from '@mui/icons-material/List';
import GitHubIcon from '@mui/icons-material/GitHub';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import { useTheme as useThemeContext } from '../../context/ThemeContext';
import { useTheme } from '@mui/material/styles';

const iconMap = {
  DashboardIcon: <DashboardIcon />,
  CodeIcon: <CodeIcon />,
  ListIcon: <ListIcon />,
  GitHubIcon: <GitHubIcon />,
  PersonIcon: <PersonIcon />,
  AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
  HomeIcon: <HomeIcon />,
  LoginIcon: <LoginIcon />,
  PersonAddIcon: <PersonAddIcon />,
};

const PermanentSidebar = ({ navItems, setIsSidebarOpen }) => {
  const { themeMode } = useThemeContext();
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const location = useLocation();

  const handleMouseEnter = () => {
    if (!isPinned) {
      setIsExpanded(true);
      setIsSidebarOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isPinned) {
      setIsExpanded(false);
      setIsSidebarOpen(false);
    }
  };

  const togglePin = () => {
    setIsPinned(!isPinned);
    setIsExpanded(!isPinned);
    setIsSidebarOpen(!isPinned);
  };

  const isCollapsed = !isExpanded && !isPinned;
  const logoSrc = themeMode === 'light'
    ? (isCollapsed ? '/assets/logos/logo-crypex-icon-light.svg' : '/assets/logos/logo-crypex-light.svg')
    : (isCollapsed ? '/assets/logos/logo-crypex-icon-dark.svg' : '/assets/logos/logo-crypex-dark.svg');

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isExpanded || isPinned ? 250 : 64,
        flexShrink: 0,
        zIndex: (theme) => theme.zIndex.drawer,
        '& .MuiDrawer-paper': {
          width: isExpanded || isPinned ? 250 : 64,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
          top: 0,
          background: themeMode === 'light'
            ? 'rgba(255, 255, 255, 0.8)'
            : 'rgba(30, 30, 30, 0.85)',
          backdropFilter: 'blur(15px)',
          WebkitBackdropFilter: 'blur(15px)',
          boxShadow: themeMode === 'dark' ? '0 2px 10px rgba(0,0,0,0.2)' : '0 2px 10px rgba(0,0,0,0.1)',
          borderRight: 'none',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: isCollapsed ? 0 : 2,
          py: 1.5,
          height: 64,
          width: '100%',
          flexShrink: 0,
          position: 'relative', // For absolute positioning of the icon
        }}
      >
        <Box
          sx={{
            position: isCollapsed ? 'absolute' : 'relative', // Use absolute positioning when collapsed
            left: isCollapsed ? '50%' : 'auto',
            transform: isCollapsed ? 'translateX(-50%)' : 'none', // Center horizontally
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'visible',
          }}
        >
          <img
            src={logoSrc}
            alt="Crypex Logo"
            style={{
              height: isExpanded || isPinned ? 36 : 48,
              width: isExpanded || isPinned ? 'auto' : 48,
              flexShrink: 0,
              objectFit: 'contain',
              display: 'block',
            }}
          />
        </Box>
        {isExpanded || isPinned ? (
          <IconButton
            onClick={togglePin}
            sx={{
              color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
              opacity: 1,
              transition: theme.transitions.create('opacity', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.short,
              }),
              '&:hover': {
                bgcolor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
              },
              flexShrink: 0,
            }}
          >
            <PushPinIcon
              sx={{
                transform: isPinned ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.short,
                }),
              }}
            />
          </IconButton>
        ) : null}
      </Box>

      <List sx={{ flexGrow: 1 }}>
        <ListSubheader
          sx={{
            color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            opacity: isExpanded || isPinned ? 1 : 0,
            transition: theme.transitions.create('opacity', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.short,
            }),
            px: 3,
            pt: 2,
            pb: 1,
            background: 'inherit',
          }}
        >
          Menu
        </ListSubheader>
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              sx={{
                py: 1.5,
                px: 3,
                color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
                bgcolor: isActive ? 'rgba(0, 163, 255, 0.2)' : 'transparent',
                borderRadius: isActive ? '8px' : 0,
                '&:hover': {
                  bgcolor: isActive
                    ? 'rgba(0, 163, 255, 0.2)'
                    : themeMode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0, 0, 0, 0.03)',
                  color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44',
                },
                transition: theme.transitions.create(['background-color', 'color'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.short,
                }),
                display: 'flex',
                alignItems: 'center',
                minHeight: 48,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 36,
                  color: isActive ? '#00A3FF' : themeMode === 'dark' ? '#A3BFFA' : '#757575',
                  transition: theme.transitions.create('color', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.short,
                  }),
                }}
              >
                {iconMap[item.icon]}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  opacity: isExpanded || isPinned ? 1 : 0,
                  transition: theme.transitions.create('opacity', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.short,
                  }),
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                    fontWeight: isActive ? 600 : 400,
                    whiteSpace: 'nowrap',
                  },
                }}
              />
              {item.text === 'GitHub Integration' && (isExpanded || isPinned) && (
                <Badge
                  badgeContent={2}
                  color="primary"
                  sx={{
                    ml: 1,
                    '& .MuiBadge-badge': {
                      fontSize: '0.7rem',
                      minWidth: '16px',
                      height: '16px',
                      bgcolor: '#00A3FF',
                    },
                  }}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default PermanentSidebar;