import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData, login, logout as apiLogout } from '../services/auth'; // Corrected import path

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const refreshAuthState = async () => {
    setIsLoading(true);
    try {
      const response = await getUserData();
      const userData = response.data;
      setUser(userData);
      setLoggedIn(true);
      setEmailVerified(userData.email_verified || false);
      setRole(userData.role || 'User');
    } catch (err) {
      console.error('Auth check failed:', err);
      setLoggedIn(false);
      setEmailVerified(false);
      setRole(null);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshAuthState(); // Check auth state on mount
  }, []);

  const handleLogin = async (username, password) => {
    try {
      await login(username, password);
      await refreshAuthState();
      navigate('/dashboard');
    } catch (err) {
      console.error('Login failed:', err);
      throw err;
    }
  };

  const handleLogout = async () => {
    try {
      await apiLogout();
      setLoggedIn(false);
      setEmailVerified(false);
      setRole(null);
      setUser(null);
      navigate('/login', { replace: true });
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };

  const value = {
    loggedIn,
    setLoggedIn,
    emailVerified,
    setEmailVerified,
    role,
    setRole,
    user,
    isLoading,
    login: handleLogin,
    logout: handleLogout,
    refreshAuthState,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);