import React, { Suspense, lazy, memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useAuth } from './context/AuthContext';
import Loading from './components/common/Loading';

const LandingPage = lazy(() => import('./components/pages/Landing/Index'));
const Login = lazy(() => import('./components/pages/Login'));
const Register = lazy(() => import('./components/pages/Register'));
const ForgotPassword = lazy(() => import('./components/pages/ForgotPassword'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/pages/TermsOfService'));
const ContactUs = lazy(() => import('./components/pages/ContactUs'));
const Dashboard = lazy(() => import('./components/features/Dashboard/DashboardMain'));
const CodeAnalysis = lazy(() => import('./components/features/CodeAnalysis/CodeAnalysis'));
const AllAnalyses = lazy(() => import('./components/features/AllAnalyses/AllAnalyses'));
const GitHubIntegration = lazy(() => import('./components/features/GitHub/GitHubIntegration'));
const UserManagement = lazy(() => import('./components/features/UserManagement/UserManagement'));
const AdminPage = lazy(() => import('./components/features/Admin/AdminPage'));

const RoutesConfig = memo(
  ({ previousAnalyses, setPreviousAnalyses }) => {
    const { loggedIn, role, emailVerified, isLoading, logout } = useAuth(); // Added logout from useAuth

    if (isLoading) {
      return <Loading />;
    }

    const restrictIfLoggedIn = (component) => {
      return loggedIn && emailVerified ? (
        <Navigate to="/dashboard" replace />
      ) : (
        <Suspense fallback={<Loading />}>{component}</Suspense>
      );
    };

    return (
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={restrictIfLoggedIn(<LandingPage />)} />
          <Route path="/login" element={restrictIfLoggedIn(<Login />)} />
          <Route path="/register" element={restrictIfLoggedIn(<Register />)} />
          <Route path="/forgot-password" element={restrictIfLoggedIn(<ForgotPassword />)} />

          <Route path="/privacy" element={<Suspense fallback={<Loading />}><PrivacyPolicy /></Suspense>} />
          <Route path="/terms" element={<Suspense fallback={<Loading />}><TermsOfService /></Suspense>} />
          <Route path="/contact" element={<Suspense fallback={<Loading />}><ContactUs loggedIn={loggedIn} /></Suspense>} />

          <Route
            path="/dashboard"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <Dashboard role={role} requestCount={previousAnalyses.requestCount} previousAnalyses={previousAnalyses} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/code-analysis"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <CodeAnalysis
                    role={role}
                    requestCount={previousAnalyses.requestCount}
                    previousAnalyses={previousAnalyses}
                    setPreviousAnalyses={setPreviousAnalyses}
                  />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/all-analyses"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <AllAnalyses previousAnalyses={previousAnalyses} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/github"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <GitHubIntegration role={role} requestCount={previousAnalyses.requestCount} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/user"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <UserManagement role={role} loggedIn={loggedIn} handleLogout={logout} /> {/* Fixed: use logout from useAuth */}
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/admin"
            element={
              loggedIn && emailVerified && role === 'Admin' ? (
                <Suspense fallback={<Loading />}>
                  <AdminPage />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />

          <Route path="*" element={<Navigate to={loggedIn && emailVerified ? '/dashboard' : '/'} replace />} />
        </Routes>
      </AnimatePresence>
    );
  },
  (prevProps, nextProps) => prevProps.previousAnalyses === nextProps.previousAnalyses
);

RoutesConfig.displayName = 'RoutesConfig';
export default RoutesConfig;