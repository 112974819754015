import React from 'react';
import { AppBar, Toolbar, IconButton, Avatar, Box, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useAuth } from '../../context/AuthContext';
import { useTheme as useThemeContext } from '../../context/ThemeContext';

const Navbar = ({ onMenuClick, onUserClick, handleLogout, isSidebarOpen }) => {
  const theme = useTheme();
  const { themeMode, toggleTheme } = useThemeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, loggedIn } = useAuth();

  const showLogo = !loggedIn || isMobile;
  const logoSrc = themeMode === 'light' ? '/assets/logos/logo-crypex-light.svg' : '/assets/logos/logo-crypex-dark.svg';

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        width: '100%',
        transition: 'none',
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          minHeight: 64,
          pl: { xs: 2, sm: loggedIn && isSidebarOpen ? '266px' : 2 },
          pr: { xs: 2, sm: 2 },
          transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showLogo && (
            <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <img
                src={logoSrc}
                alt="Crypex Logo"
                style={{ height: 36, width: 'auto' }}
              />
            </Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isMobile && loggedIn && (
            <IconButton
              onClick={onMenuClick}
              sx={{ color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44' }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <IconButton
            onClick={toggleTheme}
            sx={{
              color: themeMode === 'dark' ? '#E0E7FF' : '#00A3FF',
              '&:hover': {
                bgcolor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 163, 255, 0.1)',
              },
            }}
          >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {loggedIn && (
            <IconButton
              onClick={onUserClick}
              sx={{
                '&:hover': {
                  bgcolor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 163, 255, 0.1)',
                },
              }}
            >
              <Avatar src={user?.profilePicture} alt="User" sx={{ width: 36, height: 36 }} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;