import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useState, useMemo, createContext, useContext, useEffect } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [themeMode, setThemeMode] = useState(localStorage.getItem('theme') || 'light');

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: themeMode,
        primary: { main: '#00A3FF', light: '#4FC3F7', dark: '#0288D1' },
        secondary: { main: '#0288D1', light: '#4FC3F7', dark: '#01579B' },
        background: {
          default: themeMode === 'light'
            ? 'linear-gradient(135deg, #F5F7FA 0%, #E3EFFF 50%, #D1E0FF 100%)'
            : 'linear-gradient(135deg, #1A1A1A 0%, #2A2A2A 50%, #3A3A3A 100%)', // Darker, neutral gradient
          paper: themeMode === 'light'
            ? 'rgba(255, 255, 255, 0.95)'
            : 'rgba(30, 30, 30, 0.85)', // Frosted dark gray
          surface: themeMode === 'light'
            ? 'rgba(255, 255, 255, 0.8)'
            : 'rgba(30, 30, 30, 0.7)',
        },
        text: {
          primary: themeMode === 'light' ? '#1A2A44' : '#E0E7FF',
          secondary: themeMode === 'light' ? '#757575' : '#A3BFFA',
        },
      },
      typography: {
        fontFamily: 'Poppins, sans-serif',
        h1: { fontWeight: 700, fontSize: '2.5rem', lineHeight: 1.2 },
        h2: { fontWeight: 600, fontSize: '2rem', lineHeight: 1.3 },
        h3: { fontWeight: 600, fontSize: '1.5rem', lineHeight: 1.4 },
        h4: { fontWeight: 600, fontSize: '1.25rem', lineHeight: 1.4 },
        h6: { fontWeight: 500, fontSize: '1rem', lineHeight: 1.5 },
        body1: { fontSize: '1rem', lineHeight: 1.6 },
        body2: { fontSize: '0.875rem', lineHeight: 1.6 },
      },
      shape: { borderRadius: 12 },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              padding: '8px 16px',
              borderRadius: 8,
              boxShadow: 'none',
              transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.1s ease',
              '&:hover': {
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                transform: 'translateY(-1px)',
              },
              '&:active': {
                transform: 'translateY(0)',
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: themeMode === 'light'
                ? '0 4px 12px rgba(0,0,0,0.05)'
                : '0 4px 12px rgba(0,0,0,0.2)',
              transition: 'box-shadow 0.3s ease, transform 0.1s ease',
              borderRadius: 12,
              background: themeMode === 'light'
                ? 'rgba(255, 255, 255, 0.95)'
                : 'rgba(30, 30, 30, 0.85)',
              backdropFilter: 'blur(15px)',
              WebkitBackdropFilter: 'blur(15px)',
              '&:hover': {
                boxShadow: themeMode === 'light'
                  ? '0 6px 16px rgba(0,0,0,0.1)'
                  : '0 6px 16px rgba(0,0,0,0.3)',
                transform: 'translateY(-2px)',
              },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              background: themeMode === 'light'
                ? 'rgba(255, 255, 255, 0.8)'
                : 'rgba(30, 30, 30, 0.85)',
              backdropFilter: 'blur(15px)',
              WebkitBackdropFilter: 'blur(15px)',
              zIndex: (theme) => theme.zIndex.drawer - 1, // Below sidebar
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              background: themeMode === 'light'
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(30, 30, 30, 0.85)',
              backdropFilter: 'blur(15px)',
              WebkitBackdropFilter: 'blur(15px)',
              boxShadow: '2px 0 10px rgba(0, 0, 0, 0.2)',
              border: 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              zIndex: (theme) => theme.zIndex.drawer, // Ensure sidebar is above navbar
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              '& .MuiInputLabel-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: '0.9rem',
                fontWeight: 500,
                color: themeMode === 'light' ? '#1A2A44' : '#E0E7FF',
              },
              '& .MuiInputBase-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: '1rem',
                color: themeMode === 'light' ? '#1A2A44' : '#E0E7FF',
                background: themeMode === 'light' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)',
                borderRadius: 8,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: themeMode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
              },
            },
          },
        },
      },
    });
  }, [themeMode]);

  const toggleTheme = () => {
    const newMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newMode);
    localStorage.setItem('theme', newMode);
    document.body.setAttribute('data-theme', newMode);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--background-default',
      themeMode === 'light'
        ? 'linear-gradient(135deg, #F5F7FA 0%, #E3EFFF 50%, #D1E0FF 100%)'
        : 'linear-gradient(135deg, #1A1A1A 0%, #2A2A2A 50%, #3A3A3A 100%)'
    );
    document.documentElement.style.setProperty(
      '--text-primary',
      themeMode === 'light' ? '#1A2A44' : '#E0E7FF'
    );
    document.documentElement.style.setProperty(
      '--accent-blue',
      '#00A3FF'
    );
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.width = '100%';
  }, [themeMode]);

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);