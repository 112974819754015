import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListSubheader,
  Badge,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CodeIcon from '@mui/icons-material/Code';
import ListIcon from '@mui/icons-material/List';
import GitHubIcon from '@mui/icons-material/GitHub';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useTheme as useThemeContext } from '../../context/ThemeContext';
import { useTheme } from '@mui/material/styles';

const iconMap = {
  DashboardIcon: <DashboardIcon />,
  CodeIcon: <CodeIcon />,
  ListIcon: <ListIcon />,
  GitHubIcon: <GitHubIcon />,
  PersonIcon: <PersonIcon />,
  AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
  HomeIcon: <HomeIcon />,
  LoginIcon: <LoginIcon />,
  PersonAddIcon: <PersonAddIcon />,
};

const TemporarySidebar = ({ navItems, open, onClose }) => {
  const { themeMode } = useThemeContext();
  const theme = useTheme();
  const location = useLocation();

  const logoSrc = themeMode === 'light' ? '/assets/logos/logo-crypex-light.svg' : '/assets/logos/logo-crypex-dark.svg';

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1,
        '& .MuiDrawer-paper': {
          width: 250,
          top: 0,
          background: themeMode === 'light'
            ? 'rgba(255, 255, 255, 0.8)'
            : 'rgba(30, 30, 30, 0.85)',
          backdropFilter: 'blur(15px)',
          WebkitBackdropFilter: 'blur(15px)',
          boxShadow: '2px 0 10px rgba(0, 0, 0, 0.2)',
          border: 'none',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1.5, height: 64 }}>
        <img
          src={logoSrc}
          alt="Crypex Logo"
          style={{ height: 36, width: 'auto' }}
        />
      </Box>

      <List>
        <ListSubheader
          sx={{
            color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
            fontSize: '0.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
            px: 3,
            pt: 2,
            pb: 1,
          }}
        >
          Menu
        </ListSubheader>
        {navItems.map((item) => {
          const isActive = location.pathname === item.path;
          return (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              onClick={onClose}
              sx={{
                py: 1.5,
                px: 3,
                color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
                bgcolor: isActive ? 'rgba(0, 163, 255, 0.2)' : 'transparent',
                borderRadius: isActive ? '8px' : 0,
                '&:hover': {
                  bgcolor: isActive
                    ? 'rgba(0, 163, 255, 0.2)'
                    : themeMode === 'dark'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'rgba(0, 0, 0, 0.03)',
                  color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44',
                },
                transition: theme.transitions.create(['background-color', 'color'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.short,
                }),
                display: 'flex',
                alignItems: 'center',
                minHeight: 48,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 36,
                  color: isActive ? '#00A3FF' : themeMode === 'dark' ? '#A3BFFA' : '#757575',
                  transition: theme.transitions.create('color', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.short,
                  }),
                }}
              >
                {iconMap[item.icon]}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '0.9rem',
                    fontWeight: isActive ? 600 : 400,
                    whiteSpace: 'nowrap',
                  },
                }}
              />
              {item.text === 'GitHub Integration' && (
                <Badge
                  badgeContent={2}
                  color="primary"
                  sx={{
                    ml: 1,
                    '& .MuiBadge-badge': {
                      fontSize: '0.7rem',
                      minWidth: '16px',
                      height: '16px',
                      bgcolor: '#00A3FF',
                    },
                  }}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default TemporarySidebar;