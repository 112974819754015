import React from 'react';
import { Box, Typography, Link as MuiLink, IconButton, Divider } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { SiDiscord, SiX } from 'react-icons/si';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme as useCustomTheme } from '../../context/ThemeContext';

function Footer({ themeMode: propThemeMode }) {
  const { themeMode: contextThemeMode } = useCustomTheme();
  const themeMode = propThemeMode || contextThemeMode;

  const iconStyles = {
    color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44',
    transition: 'all 0.3s ease',
    fontSize: '1.5rem',
    padding: '8px',
    borderRadius: '50%',
    background: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
    boxShadow: themeMode === 'dark' ? '0 2px 8px rgba(0,0,0,0.2)' : '0 2px 8px rgba(0,0,0,0.1)',
    '&:hover': {
      color: '#00A3FF',
      background: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
      transform: 'scale(1.1)',
      boxShadow: '0 4px 12px rgba(0, 163, 255, 0.3)',
    },
  };

  return (
    <Box sx={{ width: '100%', m: 0, p: 0 }}>
      <Box
        sx={{
          py: { xs: 3, sm: 1.5 }, // Match Navbar's likely py: 1.5, with more on small screens
          px: { xs: 2, sm: 2 }, // Match Navbar's likely px: 2
          background: themeMode === 'light'
            ? 'rgba(255, 255, 255, 0.8)'
            : 'rgba(30, 30, 30, 0.85)',
          backdropFilter: 'blur(15px)',
          WebkitBackdropFilter: 'blur(15px)',
          borderTop: 1,
          borderColor: 'divider',
          borderRadius: 0, // Remove rounded corners to match Navbar
          boxShadow: themeMode === 'dark'
            ? '0 -2px 10px rgba(0,0,0,0.2)' // Match Navbar shadow (inverted direction)
            : '0 -2px 10px rgba(0,0,0,0.1)',
          width: '100%',
          m: 0,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: { xs: 3, sm: 5 },
              mb: 4,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                textAlign: 'center',
                color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44',
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              © {new Date().getFullYear()} Crypex. All rights reserved.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 4,
                flexWrap: 'wrap',
              }}
            >
              {[
                { text: 'Privacy Policy', href: '/privacy' },
                { text: 'Terms of Service', href: '/terms' },
                { text: 'Contact Us', href: '/contact' },
              ].map((link) => (
                <MuiLink
                  key={link.text}
                  component={RouterLink}
                  to={link.href}
                  underline="none"
                  sx={{
                    color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
                    fontWeight: 500,
                    fontSize: '0.95rem',
                    fontFamily: "'Poppins', sans-serif",
                    position: 'relative',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#00A3FF',
                      '&:after': {
                        width: '100%',
                      },
                    },
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      width: '0%',
                      height: '2px',
                      bottom: '-2px',
                      left: 0,
                      background: '#00A3FF',
                      transition: 'width 0.3s ease',
                    },
                  }}
                >
                  {link.text}
                </MuiLink>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              {[
                { href: 'https://t.me/crypex_ai', icon: <TelegramIcon /> },
                { href: 'https://discord.gg/SZg8BfzU79', icon: <SiDiscord /> },
                { href: 'https://x.com/Cryp3x_', icon: <SiX /> },
              ].map((social) => (
                <IconButton
                  key={social.href}
                  component="a"
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={iconStyles}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Box>

          <Divider
            sx={{
              my: 4,
              borderColor: 'transparent',
              background: themeMode === 'dark'
                ? 'linear-gradient(90deg, rgba(0,163,255,0), rgba(0,163,255,0.5), rgba(0,163,255,0))'
                : 'linear-gradient(90deg, rgba(0,163,255,0), rgba(0,163,255,0.3), rgba(0,163,255,0))',
              height: '1px',
            }}
          />

          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              display: 'block',
              fontStyle: 'italic',
              paddingBottom: 1,
              color: themeMode === 'dark' ? '#A3BFFA' : '#1A2A44',
              fontFamily: "'Poppins', sans-serif",
              background: 'linear-gradient(90deg, #00A3FF, #4FC3F7)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              position: 'relative',
              animation: 'gradientShift 3s ease infinite',
              '@keyframes gradientShift': {
                '0%': {
                  backgroundPosition: '0% 50%',
                },
                '50%': {
                  backgroundPosition: '100% 50%',
                },
                '100%': {
                  backgroundPosition: '0% 50%',
                },
              },
              backgroundSize: '200% 200%',
            }}
          >
            Built with ❤️ by the Crypex Team
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;