import React from 'react';
import { Box, CircularProgress, Typography, keyframes } from '@mui/material';
import { useTheme as useCustomTheme } from '../../context/ThemeContext';

const pulse = keyframes`
  0% { transform: scale(0.98); opacity: 0.8; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.98); opacity: 0.8; }
`;

const Loading = () => {
  const { themeMode } = useCustomTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: themeMode === 'light'
          ? 'rgba(255, 255, 255, 0.8)' // Match Navbar
          : 'rgba(30, 30, 30, 0.85)', // Match Navbar
        backdropFilter: 'blur(15px)',
        WebkitBackdropFilter: 'blur(15px)',
        zIndex: 9999,
      }}
    >
      <CircularProgress
        size={60}
        thickness={5}
        sx={{
          color: '#00A3FF', // Match Navbar accent
          animation: `${pulse} 1.5s ease-in-out infinite`,
          '& .MuiCircularProgress-circle': { strokeLinecap: 'round' },
        }}
        className="allow-animations"
      />
      <Typography
        variant="h6"
        sx={{
          mt: 3,
          fontFamily: 'Poppins, sans-serif',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: themeMode === 'light' ? '#1A2A44' : '#E0E7FF', // Match Navbar primary text
          animation: `${pulse} 1.5s ease-in-out infinite`,
          background: 'linear-gradient(90deg, #00A3FF, #4FC3F7)', // Match app's gradient
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        className="allow-animations"
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default Loading;