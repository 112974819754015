import React, { useState, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Box, CssBaseline, useMediaQuery, Menu, MenuItem } from '@mui/material';
import { ThemeProvider, useTheme as useThemeContext } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SnackbarProvider } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import RoutesConfig from './RoutesConfig';
import PermanentSidebar from './components/common/PermanentSidebar';
import TemporarySidebar from './components/common/TemporarySidebar';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Loading from './components/common/Loading';
import styles from './styles/App.module.css';

const stripePromise = loadStripe('pk_test_your_publishable_key_here');

const AppContent = () => {
  const { logout, isLoading, loggedIn, emailVerified, role } = useAuth();
  const { themeMode } = useThemeContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [previousAnalyses, setPreviousAnalyses] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile); // Initialize based on device
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = useCallback(() => {
    logout();
    setPreviousAnalyses([]);
  }, [logout]);

  const navItems = useMemo(() => {
    return loggedIn && emailVerified
      ? [
          { text: 'Dashboard', path: '/dashboard', icon: 'DashboardIcon' },
          { text: 'Code Analysis', path: '/dashboard/code-analysis', icon: 'CodeIcon' },
          { text: 'All Analyses', path: '/dashboard/all-analyses', icon: 'ListIcon' },
          { text: 'GitHub Integration', path: '/dashboard/github', icon: 'GitHubIcon' },
          { text: 'User Management', path: '/dashboard/user', icon: 'PersonIcon' },
          ...(role === 'Admin' ? [{ text: 'Admin', path: '/dashboard/admin', icon: 'AdminPanelSettingsIcon' }] : []),
        ]
      : [
          { text: 'Home', path: '/', icon: 'HomeIcon' },
          { text: 'Login', icon: 'LoginIcon', path: '/login' },
          { text: 'Sign Up', icon: 'PersonAddIcon', path: '/register' },
        ];
  }, [loggedIn, emailVerified, role]);

  const profileMenuItems = useMemo(() => [
    { text: 'Profile', path: '/dashboard/user?tab=0' },
    { text: 'Password', path: '/dashboard/user?tab=1' },
    { text: 'Discord', path: '/dashboard/user?tab=2' },
    { text: 'Subscription', path: '/dashboard/user?tab=3' },
    { text: 'Logout', action: handleLogout },
  ], [handleLogout]);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  if (isLoading) return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: (theme) => theme.palette.background.default,
        transition: 'none',
        '& *': { transition: 'none' },
        overflowX: 'hidden',
      }}
      className={`${styles.app} no-global-animations`}
    >
      {/* Sidebar - Only show when logged in */}
      {loggedIn && emailVerified && (
        isMobile ? (
          <TemporarySidebar
            navItems={navItems}
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
        ) : (
          <PermanentSidebar navItems={navItems} setIsSidebarOpen={setIsSidebarOpen} />
        )
      )}

      {/* Main Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Navbar
          onMenuClick={toggleSidebar}
          onUserClick={handleProfileClick}
          handleLogout={handleLogout}
          isSidebarOpen={isSidebarOpen}
        />
        <Box
          component="main"
          className={styles.content}
          sx={{
            flexGrow: 1,
            px: { xs: 2, md: 4 },
            py: 3,
            width: '100%',
            bgcolor: 'transparent',
            mt: 8,
          }}
        >
          <RoutesConfig
            previousAnalyses={previousAnalyses}
            setPreviousAnalyses={setPreviousAnalyses}
          />
        </Box>
        <Box
          component="footer"
          sx={{
            width: '100%',
          }}
        >
          <Footer themeMode={themeMode} />
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            mt: 1,
            borderRadius: 8,
            boxShadow: themeMode === 'dark'
              ? '0 4px 12px rgba(0,0,0,0.2)'
              : '0 4px 12px rgba(0,0,0,0.1)',
            background: themeMode === 'light'
              ? 'rgba(255, 255, 255, 0.8)'
              : 'rgba(30, 30, 30, 0.85)',
            backdropFilter: 'blur(15px)',
            WebkitBackdropFilter: 'blur(15px)',
            border: `1px solid ${themeMode === 'dark' ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)'}`,
          },
        }}
      >
        {profileMenuItems.map((item) => (
          <MenuItem
            key={item.text}
            component={item.path ? Link : 'button'}
            to={item.path}
            onClick={() => {
              if (item.action) item.action();
              handleProfileClose();
            }}
            sx={{
              py: 1.5,
              px: 3,
              fontSize: '0.9rem',
              color: themeMode === 'dark' ? '#A3BFFA' : '#757575',
              '&:hover': {
                bgcolor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 163, 255, 0.1)',
                color: themeMode === 'dark' ? '#E0E7FF' : '#1A2A44',
              },
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Elements stripe={stripePromise}>
              <AppContent />
            </Elements>
          </SnackbarProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;