import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === 'production' ? 'https://api.crypex.net' : 'http://127.0.0.1:8080');

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Enable sending cookies
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Skip retry for login or initial auth check to avoid redirect loops
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== '/auth/login' && // Skip for login
      originalRequest.url !== '/auth/me'      // Skip for initial user data fetch
    ) {
      originalRequest._retry = true;
      try {
        const response = await axios.post(`${API_BASE_URL}/auth/refresh`, {}, { withCredentials: true });
        if (response.status === 200) {
          return api(originalRequest); // Retry with new token from cookie
        }
      } catch (refreshError) {
        // Let AuthContext handle navigation instead of forcing redirect here
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const register = async (userData) => {
  return await api.post('/auth/register', userData);
};

export const verifyOtp = async (email, otp) => {
  return await api.post('/auth/verify-otp', { email, otp });
};

export const login = async (username, password) => {
  return await api.post('/auth/login', { username, password });
};

export const logout = async () => {
  return await api.post('/auth/logout');
};

export const forgotPassword = async (email) => {
  return await api.post('/auth/forgot-password', { email });
};

export const resetPassword = async (token, new_password) => {
  return await api.post('/auth/reset-password', { token, new_password });
};

export const resendOtp = async (email) => {
  return await api.post('/auth/resend-otp', { email });
};

export const getUserData = async () => {
  return await api.get('/auth/me');
};

export const getPublicRegistrationStatus = async () => {
  return await api.get('/auth/registration-status');
};

export const isAuthenticated = async () => {
  try {
    await getUserData();
    return true;
  } catch (err) {
    return false;
  }
};